.p-scrollpanel {
    .p-scrollpanel-bar {
        background: $scrollPanelTrackBg;
        border: $scrollPanelTrackBorder;
        transition: $formElementTransition;

        &:focus-visible {
            @include focused();
        }
    }
}