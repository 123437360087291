.p-megamenu {
    padding: $horizontalMenuPadding;
    background: $horizontalMenuBg;
    color: $horizontalMenuTextColor;
    border: $horizontalMenuBorder;
    border-radius: $borderRadius;

    .p-megamenu-root-list {  
        outline: 0 none;
    }

    .p-menuitem {
        @include menuitem();
    }

    .p-megamenu-panel {
        background: $overlayMenuBg;
        color: $menuTextColor;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
        border-radius: $borderRadius;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
        border-top-right-radius: $borderRadius; 
        border-top-left-radius: $borderRadius; 
    }

    .p-submenu-list {
        padding: $verticalMenuPadding;
        width: $menuWidth;

        .p-menuitem-separator {
            border-top: $divider;
            margin: $menuSeparatorMargin;
        }
    }

    &.p-megamenu-vertical {
        width: $menuWidth;
        padding: $verticalMenuPadding;
    }

    &.p-megamenu-horizontal {
        .p-megamenu-root-list {  
            > .p-menuitem {
                @include horizontal-rootmenuitem();
            }
        }
    }
}