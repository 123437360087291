@import "../../variables.scss";

:root {
    --gray-50: #f9fafb;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    margin-right: 0;
}

.p-icon-wrapper {
    display: inline-flex !important;
}

.p-tag.p-tag-info {
    background-color:  var(--dark-gray) !important;
}

.p-inputswitch .p-inputswitch-slider {
    border: none;
}

.w-full>.p-chips {
    width: 100%;
}

.w-full>.p-chips-multiple-container {
    width: 100%;
}

.p-dialog {
    max-width: 90vw;
    max-height: 90vh;    

    .p-dialog-header-icon {
        color: #fff;
    }

    &.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: unset;
    }
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    white-space: unset !important;
}

.p-inputnumber-input {
    width: 100%;
}

.p-datepicker {
    overflow: auto;
}

.p-frozen-column {
    border-width: 0 1px 1px 1px;
}

// mobile menubar
@media screen and (max-width: $lgWidth) {
    
    .p-menubar .p-menubar-button:hover {
        color: var(--primary-color);
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
    }

    .p-menubar .p-menubar-root-list .p-menuitem.p-focus>.p-menuitem-content>.p-menuitem-link {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 .15rem rgba(38, 143, 255, .5);
    }

    .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
        background: transparent;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        border-radius: 0;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-icon-wrapper {
        margin-left: .5rem;
    }
}


