.p-toolbar {
    background: $panelHeaderBg;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    border-radius: $borderRadius;
    gap: $inlineSpacing;

    .p-toolbar-separator {
        margin: 0 $inlineSpacing;
    }
}
