// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "./variables.scss";

$font-family: "Arial";
$dark-gray: #4B4B4B;

:root {
    --font-family: $font-family;
    --black-dark: #232323;
    --dark-gray: #{$dark-gray};
    --darker-gray: #{darken($dark-gray, 10%)};
    --grey-lighter: #F1F1F1;
    $lg: 500px !important;
}

* {
    font-family: #{$font-family};   
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font-family: #{$font-family};
    background-color: #F3EEE9;
}

.bg-black-dark {
    background-color: var(--black-dark);
}

.bg-dark-gray {
    background-color: var(--dark-gray);
}

.bg-highlight {
    background-color: var(--highlight-bg);
}

.bg-grey-lighter {
    background-color: var(--grey-lighter);
}

// for md and more
@media screen and (min-width: $mdWidth) {

    body.secondary-menu-expanded {
        .primary-menu {
            width: var(--menu-primary-expanded-width);
        }

        .secondary-menu {
            width: var(--menu-secondary-width);
        }
        
        .content-container, .footer-container {
            margin-left: calc(var(--menu-primary-expanded-width) + var(--menu-secondary-width));
        }
    }

    body.secondary-menu-not-expanded {
        .primary-menu {
            width: var(--menu-primary-not-expanded-width);
        }

        .secondary-menu {
            width: 0;
        }

        .content-container, .footer-container {
            margin-left: var(--menu-primary-not-expanded-width);
        }
    }

    body:not(.secondary-menu-expanded):not(.secondary-menu-not-expanded) {
        .primary-menu {
            width: var(--menu-primary-not-expanded-width);
        }

        .secondary-menu {
            width: 0;
        }

        .content-container, .footer-container {
            margin-left: var(--menu-primary-not-expanded-width);
        }
    }
}
